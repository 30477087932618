import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faInstagram,
  faYoutube,
  faLinkedin,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
  const [mobile, setmobile] = useState(false);
  const [search, setsearch] = useState(false);
  const [homeDrop, sethomeDrop] = useState(false);
  const [headerDrop, setheaderDrop] = useState(false);
  const [servicesDrop, setservicesDrop] = useState(false);
  const [featuresDrop, setfeaturesDrop] = useState(false);
  const [newsDrop, setnewsDrop] = useState(false);
  const [pagesDrop, setpagesDrop] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const [menu, setmenu] = useState({});

  const activeMenu = () => {
    if (path === "/home" || path === "/home-02" || path === "/home-03") {
      setmenu({ home: true });
    } else if (path === "/about") {
      setmenu({ about: true });
    } else if (path === "/service" || path === "/servicedetails") {
      setmenu({ services: true });
    } else if (path === "/emicalculator") {
      setmenu({ emicalculator: true });
    } else if (path === "/preeligibility") {
      setmenu({ preeligibility: true });
    } else if (
      path === "/team" ||
      path === "/teamdetails" ||
      path === "/creditaudit" ||
      path === "/creditrepair" ||
      path === "/careers" ||
      path === "/faqs" ||
      path === "/applynow"
    ) {
      setmenu({ pages: true });
    } else if (
      path === "/personalloan" ||
      path === "/loaneligibility" ||
      path === "/homeloan" ||
      path === "/homeloaneligibility" ||
      path === "/compareEMI" ||
      path === "/monthlyloan"
    ) {
      setmenu({ features: true });
    } else if (path === "/newsmain" || path === "/newsdetails") {
      setmenu({ news: true });
    } else if (path === "/contact") {
      setmenu({ contact: true });
    } else {
      setmenu({ home: true });
    }
  };

  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    activeMenu();
  }, [path]);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 141 ? setSticky(true) : setSticky(false);
  };

  return (
    <>
      <div className="page-wrapper">
        {/* <div className="topbar">
          <div className="container-fluid">
            <div className="topbar__info">
              <Link to="">
                <i className="icon-email"></i> Customersupport@mybankshoppy
              </Link>
            </div>
            <div className="topbar__links">
              <Link to="/service">Available loans </Link>

              <Link to="/service">AutoCar </Link>
              <Link to="/service">Wedding </Link>
              <Link to="/service">Property </Link>
              <Link to="/service">Business </Link>
              <Link to="/service">Education </Link>
              <Link to="/service">Personal </Link>
            </div>
            <div className="topbar__social">
              <Link to="#">
                <FontAwesomeIcon icon={faXTwitter} />
              </Link>
              <Link to="#">
                <FontAwesomeIcon icon={faInstagram} />
              </Link>
              <Link to="#">
                <FontAwesomeIcon icon={faYoutube} />
              </Link>
              <Link to="#">
                <FontAwesomeIcon icon={faLinkedin} />
              </Link>
              <Link to="#">
                <FontAwesomeIcon icon={faFacebook} />
              </Link>
              <Link to="#">
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
            </div>
          </div>
        </div> */}
        <nav
          className={`main-menu ${
            sticky && "stricky-header stricked-menu stricky-fixed"
          }`}
        >
          <div className="container-fluid">
            <div className="main-menu__logo">
              <Link to="/home">
                <img
                  src="assets/images/mybankshoppylogo.png"
                  width="auto"
                  height="80"
                  alt="mybankshoppylogo"
                />
              </Link>
            </div>
            <div className="main-menu__nav">
              <ul className="main-menu__list">
                <li className={`dropdown ${menu.home && "current"}`}>
                  <Link to="/home">Home</Link>
                </li>

                <li className={`about ${menu.about && "current"}`}>
                  <Link to="/about">About</Link>
                </li>

                <li className={`service ${menu.services && "current"}`}>
                  <Link to="/service">Services</Link>
                </li>
                {/* <li
                  className={`emicalculator ${menu.emicalculator && "current"}`}
                >
                  <Link to="/emicalculator">EMI Calculator</Link>
                </li> */}
                <li
                  className={`preeligibility ${
                    menu.preeligibility && "current"
                  }`}
                >
                  <Link to="/preeligibility">Eligibility</Link>
                </li>
                <li className={`contact ${menu.contact && "current"}`}>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="main-menu__right">
              <Link
                to="#"
                onClick={() => setmobile(true)}
                className="main-menu__toggler mobile-nav__toggler"
              >
                <i className="fa fa-bars"></i>
              </Link>
              <Link
                to="#"
                onClick={() => setsearch(true)}
                className="main-menu__search search-toggler"
              >
                <i className="icon-magnifying-glass"></i>
              </Link>
              <Link
                to="/login"
                className="thm-btn main-menu__btn"
                style={{ marginRight: "20px" }}
              >
                Login
              </Link>
              <Link to="/applynow" className="thm-btn main-menu__btn">
                Request Loan
              </Link>
              <Link to="tel:98840 63673" className="main-menu__contact">
                <span className="main-menu__contact__icon">
                  <i className="icon-phone"></i>
                </span>
                <span className="main-menu__contact__text">
                  <strong>+91 98840 63673</strong>
                  Mon to Fri: 9 am to 6 pm
                </span>
              </Link>
            </div>
          </div>
        </nav>
      </div>

      <div className={`search-popup ${search && "active"}`}>
        <div
          className="search-popup__overlay search-toggler"
          onClick={() => setsearch(false)}
        ></div>
        <div className="search-popup__content">
          <form action="#">
            <label htmlFor="search" className="sr-only">
              search here
            </label>
            <input type="text" id="search" placeholder="Search Here..." />
            <button
              type="submit"
              aria-label="search submit"
              className="thm-btn"
            >
              <i className="icon-magnifying-glass"></i>
            </button>
          </form>
        </div>
      </div>

      <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
        <div
          className="mobile-nav__overlay mobile-nav__toggler"
          onClick={() => setmobile(false)}
        ></div>
        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={() => setmobile(false)}
          >
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <Link to="/home" aria-label="logo image">
              <img
                src="assets/images/mybankshoppylogo.png"
                width="155"
                alt=""
              />
            </Link>
          </div>

          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li className="dropdown current">
                <Link
                  to="/home"
                  className={homeDrop ? "expanded" : ""}
                  onClick={() => setmobile(false)}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={() => setmobile(false)}>
                  About
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  to="/service"
                  className={servicesDrop ? "expanded" : ""}
                  onClick={() => setmobile(false)}
                >
                  Services
                </Link>
              </li>

              {/* <li>
                <Link to="/emicalculator" onClick={() => setmobile(false)}>
                  EMI Calculator
                </Link>
              </li> */}

              <li>
                <Link to="/preeligibility" onClick={() => setmobile(false)}>
                  Eligibility
                </Link>
              </li>

              <li>
                <Link to="/contact" onClick={() => setmobile(false)}>
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/login" onClick={() => setmobile(false)}>
                  Login
                </Link>
              </li>
              <li>
                <Link to="/applynow" onClick={() => setmobile(false)}>
                  Request Loan
                </Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="icon-email"></i>
              <Link to="mailto:sales@mybankshoppy.com">
                sales@mybankshoppy.com
              </Link>
            </li>
            <li>
              <i className="icon-telephone"></i>
              <Link to="tel:+91 9884063673">+91 9884063673</Link>
            </li>
          </ul>

          <div className="mobile-nav__social">
            <Link to="#">
              <FontAwesomeIcon icon={faXTwitter} />
            </Link>
            <Link to="#">
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link to="#">
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
            <Link to="#">
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>
            <Link to="#">
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link to="#">
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
