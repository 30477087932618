import React from "react";

function Maptwo() {
  return (
    <>
      <div className="google-map__default container">
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-4">
            <div className="branch-map">
              <h3>Pattabiram</h3>
              <iframe
                title="Branch 2 Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.179623978159!2d80.063424!3d13.1180776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5289ad73bd614b%3A0x83825b5ee2d22412!2sAPPLE+PHARMACY%2C+Pattabiram!5e0!3m2!1sen!2sin!4v1691613096144!5m2!1sen!2sin"
                className="map__default"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          {/* Branch 1 */}
          <div className="col-md-6 col-sm-12 mb-4">
            <div className="branch-map">
              <h3>West Tambaram</h3>
              <iframe
                title="Branch 1 Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.526123980051!2d80.1070215!3d12.9266187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525fedd6bdbf21%3A0xf4c2dfc7e71d45c8!2sNo%3A2%2F5%2C%20Rajarathinam%20Street%2C%20West%20Tambaram%2C%20Chennai%2C%20Tamil%20Nadu%20600045!5e0!3m2!1sen!2sin!4v1691612926144!5m2!1sen!2sin"
                className="map__default"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          {/* Branch 2 */}
        </div>
      </div>
    </>
  );
}

export default Maptwo;
